require('../libs/utils.exec.js');

import blob1 from '../assets/blobs/blob1.png';
import blob2 from '../assets/blobs/blob2.png';
import blob3 from '../assets/blobs/blob3.png';
import blob4 from '../assets/blobs/blob4.png';
import blob5 from '../assets/blobs/blob5.png';
import blob6 from '../assets/blobs/blob6.png';
import blob7 from '../assets/blobs/blob7.png';
import blob8 from '../assets/blobs/blob8.png';
import blob9 from '../assets/blobs/blob9.png';
import blob10 from '../assets/blobs/blob10.png';
import blob11 from '../assets/blobs/blob11.png';
import blob12 from '../assets/blobs/blob12.png';
import blob13 from '../assets/blobs/blob13.png';
import blob14 from '../assets/blobs/blob14.png';
import blob15 from '../assets/blobs/blob15.png';

import amriImg from '../assets/clients/amri.png';
import airtelImg from '../assets/clients/airtel.png';
import itcImg from '../assets/clients/itc_logo.png';
import denImg from '../assets/clients/den.png';
import kokilabenImg from '../assets/clients/kokilaben.png';
import gtsImg from '../assets/clients/gts.png';
import medantaImg from '../assets/clients/medanta.png';

const df=20;
const DP={
    /*-------Blob data providers------------------------------------*/
    //home
    p1_portrait:[
        {texImg:blob4,p:v3(-21,62,-3*df),s:v3(4.74,4.74,1),name:'blob4',rZ:-3.08},
        {texImg:blob6,p:v3(-14,-28,0),s:v3(1.75,1.75,1),name:'blob6a'},
        {texImg:blob6,p:v3(-19,-22,0),s:v3(0.75,0.75,1),name:'blob6b'},
        {texImg:blob7,p:v3(-13,-20,-1*df),s:v3(0.97,0.97,1),name:'blob7'},
        {texImg:blob8,p:v3(16,38,0),s:v3(3.25,3.25,1),name:'blob8',rZ:-1.9},
        {texImg:blob9,p:v3(19,-30,-2*df),s:v3(13.47,13.47,1),name:'blob9',rZ:0.3},
        {texImg:blob10,p:v3(4,-17,0),s:v3(2.2,2.2,1),name:'blob10'}
    ],
    p1:[
        {texImg:blob1,p:v3(-32,19,0),s:v3(2.5,2.5,1),name:'blob1'},
        {texImg:blob2,p:v3(9,22.5,0),s:v3(2,2,1),name:'blob2'},
        {texImg:blob3,p:v3(85.5,39,-4*df),s:v3(6.7,6.7,1),name:'blob3'},
        {texImg:blob4,p:v3(62,21.5,-3*df),s:v3(7.14,7.14,1),name:'blob4'},
        {texImg:blob5,p:v3(22.6,-24,-1*df),s:v3(3.06,3.06,1),name:'blob5'},
        {texImg:blob6,p:v3(20,-18,0),s:v3(1.75,1.75,1),name:'blob6a'},
        {texImg:blob6,p:v3(-11,-10,0),s:v3(0.75,0.75,1),name:'blob6b'},
        {texImg:blob7,p:v3(39,-30,-1*df),s:v3(0.97,0.97,1),name:'blob7'},
        {texImg:blob8,p:v3(0,-15,0),s:v3(3.25,3.25,1),name:'blob8'},
        {texImg:blob9,p:v3(32,-6,-2*df),s:v3(13.47,13.47,1),name:'blob9'},
        {texImg:blob10,p:v3(7,0,0),s:v3(2.2,2.2,1),name:'blob10'}
    ],

    //approach
    p2_portrait:[
        {texImg:blob6,p:v3(18,-6,-1*df),s:v3(0.77,0.77,1),name:'blob6a'},
        {texImg:blob11,p:v3(-1,-9,-1*df),s:v3(-9,9,1),name:'blob11',rZ:-0.03},
        {texImg:blob6,p:v3(-25,-13,-2*df),s:v3(2.29,2.29,1),name:'blob6b'},
        {texImg:blob3,p:v3(0,-37,-2*df),s:v3(7.66,7.66,1),name:'blob3',rZ:-0.8},
        {texImg:blob7,p:v3(15,-32,-1*df),s:v3(0.64,0.64,0.64),name:'blob7'},
    ],
    p2:[
        {texImg:blob1,p:v3(-32,19,0),s:v3(2.5,2.5,1),name:'blob1'},
        {texImg:blob12,p:v3(5,-8.5,0),s:v3(4.4,4.4,1),name:'blob12'},
        {texImg:blob6,p:v3(24,-15,0),s:v3(0.77,0.77,1),name:'blob6a'},
        {texImg:blob11,p:v3(21,0,-1*df),s:v3(12.24,12.24,1),name:'blob11'},
        {texImg:blob6,p:v3(-14,-23,-2*df),s:v3(3.45,3.45,1),name:'blob6b'},            
        {texImg:blob13,p:v3(6,32,-2*df),s:v3(6.4,6.4,1),name:'blob13'},      
        {texImg:blob3,p:v3(43,-22,-2*df),s:v3(7.66,7.66,1),name:'blob3'},
        {texImg:blob7,p:v3(72.5,-34,-3*df),s:v3(1,1,1),name:'blob7'},
    ],

    //speed
    p3_portrait:[
        {texImg:blob6,p:v3(15,-15,0),s:v3(1.82,1.82,1),name:'blob6'},
        {texImg:blob7,p:v3(14,22,0),s:v3(0.63,0.63,0.63),name:'blob7'},
        {texImg:blob1,p:v3(-12.5,-4.5,0),s:v3(2.26,2.26,1),name:'blob1'},
        {texImg:blob11,p:v3(8,-4,-2*df),s:v3(9.75,9.75,1),name:'blob11',rZ:-0.4},    
        {texImg:blob3,p:v3(-2,-24,-2*df),s:v3(6.43,6.43,1),name:'blob3',rZ:-0.2},
        {texImg:blob13,p:v3(-21,1,-3*df),s:v3(5,5,1),name:'blob13'}
    ],
    p3:[
        {texImg:blob12,p:v3(-34,19.1,0),s:v3(2.4,2.4,1),name:'blob12'},
        {texImg:blob6,p:v3(34,-16,0),s:v3(1.82,1.82,1),name:'blob6'},
        {texImg:blob7,p:v3(34,10,0),s:v3(0.63,0.63,0.63),name:'blob7'},
        {texImg:blob1,p:v3(0,-2,0),s:v3(4.4,4.4,1),name:'blob1'},
        {texImg:blob11,p:v3(44,5,-2*df),s:v3(14.7,14.7,1),name:'blob11',rZ:-0.6},
        {texImg:blob3,p:v3(25,-22,-2*df),s:v3(10.2,10.2,1),name:'blob3',rZ:-0.4},
        {texImg:blob13,p:v3(1,35,-3*df),s:v3(9.4,9.4,1),name:'blob13'}
    ],

    //work - individual
    p4_portrait:[
        {texImg:blob14,p:v3(-15.5,41.1,0),s:v3(2.06,2.06,1),name:'blob14',v:false},
        {texImg:blob11,p:v3(0,-24,0),s:v3(8.3,8.3,1),name:'blob11',rZ:1.6,v:false},
    ],
    p4:[
        {texImg:blob14,p:v3(-32.5,19.1,0),s:v3(2.06,2.06,1),name:'blob14',v:false},
        {texImg:blob6,p:v3(49,16,-2*df),s:v3(4.55,4.55,1),name:'blob6',v:false},
        {texImg:blob11,p:v3(13,-6,0),s:v3(8.3,8.3,1),name:'blob11',rZ:1.6,v:false},
    ],

    //clients
    p5_portrait:[
        {texImg:blob9,p:v3(6,-25,-2*df),s:v3(12.67,12.67,1),name:'blob9',rZ:0.5},
    ],
    p5:[
        {texImg:blob9,p:v3(25,-8,-2*df),s:v3(14.85,14.85,1),name:'blob9',rZ:0.5},
        {texImg:blob10,p:v3(4,-3,0),s:v3(2.43,2.43,1),name:'blob10'},
    ],

    //testimonials
    p6_portrait:[
        {texImg:blob2,p:v3(16,-15.5,0),s:v3(3.4,3.4,1),name:'blob2',rZ:-0.8},
        {texImg:blob6,p:v3(18,-61,-2*df),s:v3(0.93,0.93,1),name:'blob6a'},
        {texImg:blob6,p:v3(-12,-53,0),s:v3(2.01,2.01,1),name:'blob6b'},
        {texImg:blob15,p:v3(9,-39,-1*df),s:v3(11.49,11.49,1),name:'blob15',rZ:-0.8},
    ],
    p6:[
        {texImg:blob14,p:v3(-32.5,19.1,0),s:v3(2.06,2.06,1),name:'blob14'},
        {texImg:blob2,p:v3(2,21.5,0),s:v3(3.4,3.4,1),name:'blob2'},
        {texImg:blob1,p:v3(31.25,0,0),s:v3(4.07,4.07,1),name:'blob1'},
        {texImg:blob6,p:v3(3,-25,-2*df),s:v3(1.4,1.4,1),name:'blob6a'},
        {texImg:blob6,p:v3(-8,-13,0),s:v3(2.01,2.01,1),name:'blob6b'},
        {texImg:blob15,p:v3(21,8,-1*df),s:v3(12.41,12.41,1),name:'blob15'},
    ],

    //aboutus
    p7_portrait:[
        {texImg:blob14,p:v3(8,-31,0),s:v3(3.13,3.13,1),name:'blob14',rZ:0.6},
        {texImg:blob8,p:v3(3,-26,-1*df),s:v3(8.57,8.57,1),name:'blob8a',rZ:-0.7},
    ],
    p7:[
        {texImg:blob6,p:v3(-32.5,19.1,0),s:v3(1.22,1.22,1),name:'blob6'},
        {texImg:blob1,p:v3(2,7,0),s:v3(2.72,2.72,1),name:'blob1'},
        {texImg:blob14,p:v3(23,-11,0),s:v3(3.13,3.13,1),name:'blob14',rZ:0.6},
        {texImg:blob8,p:v3(28,-7,-1*df),s:v3(10.04,10.04,1),name:'blob8a',rZ:-0.7},
        {texImg:blob8,p:v3(40,35,-2*df),s:v3(9.61,9.61,1),name:'blob8b',rZ:-0.5},
    ],

    /*-------Star background for Work page grid---------------------*/
    starStyle_1:[
        { top: 41, left: 16, bgColor: 'rgb(87, 102, 228)', padding: 2 },
        { top: 26, left: 30, bgColor: 'rgb(87, 102, 228)', padding: 0 },
        { top: 10, left: 40, bgColor: 'rgb(87, 102, 228)', padding: 0.5 },
        { top: 14, left: 67, bgColor: 'rgb(94, 105, 209)', padding: 1 },
        { top: 29, left: 73, bgColor: 'rgb(87, 102, 228)', padding: 0 },
        { top: 52, left: 73, bgColor: 'rgb(94, 105, 209)', padding: 0.5 },
        { top: 65, left: 65, bgColor: 'rgb(87, 102, 228)', padding: 2 },
        { top: 69, left: 57, bgColor: 'rgb(94, 105, 209)', padding: 0 },
        { top: 75, left: 33, bgColor: 'rgb(94, 105, 209)', padding: 1 },
        { top: 68, left: 21, bgColor: 'rgb(87, 102, 228)', padding: 0.5 }
    ],
    starStyle_2:[
        { top: 35, left: 27, bgColor: 'rgb(87, 102, 228)', padding: 1 },
        { top: 16, left: 29, bgColor: 'rgb(87, 102, 228)', padding: 0.5 },
        { top: 14, left: 50, bgColor: 'rgb(87, 102, 228)', padding: 3 },
        { top: 24, left: 60, bgColor: 'rgb(87, 102, 228)', padding: 0.5 },
        { top: 41, left: 79, bgColor: 'rgb(94, 105, 209)', padding: 2 },
        { top: 56, left: 85, bgColor: 'rgb(94, 105, 209)', padding: 0.5 },
        { top: 65, left: 79, bgColor: 'rgb(87, 102, 228)', padding: 1 },
        { top: 79, left: 48, bgColor: 'rgb(87, 102, 228)', padding: 1 },
        { top: 64, left: 31, bgColor: 'rgb(94, 105, 209)', padding: 0.5 },
        { top: 55, left: 19, bgColor: 'rgb(94, 105, 209)', padding: 0 }
    ],

    /*-------Approach Constellation---------------------------------------------*/
    approachConstellation:{
        nodes: [
            { node: 'n0', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: ((window.innerWidth<window.innerHeight)||isIOSAndroid)?100:90, top: 0, } },
            { node: 'n1', data: { isIcon: false, iconImg: '', title: 'Problem solving', titlePos: 'bottom', subTitle: '', left: 82, top: 25, } },
            { node: 'n2', data: { isIcon: false, iconImg: '', title: 'Intuitive design', titlePos: 'right', subTitle: '', left: 50, top: 37, } },
            { node: 'n3', data: { isIcon: false, iconImg: '', title: 'Algorithms', titlePos: 'top', subTitle: '', left: 90, top: 64, } },
            { node: 'n4', data: { isIcon: false, iconImg: '', title: 'Programming', titlePos: 'bottom', subTitle: '', left: isIOSAndroid?54:44, top: 72, } },
            { node: 'n5', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: isIOSAndroid?100:50, top: 100, } }
        ],
        totalLevels: 1,
        paths: [{ path: ['n0', 'n1', 'n2', 'n3', 'n4', 'n5'], level: 1 }],
        svgW: window.innerWidth,
        svgH: window.innerHeight
    },
    approachConstellation_portrait:{
        nodes: [
            { node: 'n0', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: ((window.innerWidth<window.innerHeight)||isIOSAndroid)?100:90, top: 0, } },
            { node: 'n1', data: { isIcon: false, iconImg: '', title: 'Problem solving', titlePos: 'bottom', subTitle: '', left: 72, top: 25, } },
            { node: 'n2', data: { isIcon: false, iconImg: '', title: 'Intuitive design', titlePos: 'top', subTitle: '', left: 15, top: 37, } },
            { node: 'n3', data: { isIcon: false, iconImg: '', title: 'Algorithms', titlePos: 'left', subTitle: '', left: 63, top: 64, } },
            { node: 'n4', data: { isIcon: false, iconImg: '', title: 'Programming', titlePos: 'left', subTitle: '', left: 42, top: 87, } },
            { node: 'n5', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: 100, top: 100, } }
        ],
        totalLevels: 1,
        paths: [{ path: ['n0', 'n1', 'n2', 'n3', 'n4', 'n5'], level: 1 }],
        svgW: window.innerWidth,
        svgH: window.innerHeight
    },

    /*-------Tech stack Constellation---------------------------------------------*/
    techstackConstellation:{
        nodes: [
            { node: 'n0', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: ((window.innerWidth<window.innerHeight)||isIOSAndroid)?100:87.7, top: 0 } },
            { node: 'n1', data: { isIcon: false, iconImg: '', title: 'Javascript', titlePos: 'left', subTitle: '', left: 82, top: 23.63 } },
            { node: 'n2', data: { isIcon: false, iconImg: '', title: 'MongoDB', titlePos: 'right', subTitle: '', left: 72.92, top: 38.25 } },
            { node: 'n3', data: { isIcon: false, iconImg: '', title: 'node.js', titlePos: 'top', subTitle: '', left: 50.97, top: 37.88 } },
            { node: 'n4', data: { isIcon: false, iconImg: '', title: 'PostgreSQL', titlePos: 'right', subTitle: '', left: 41.46, top: 49.21 } },
            { node: 'n5', data: { isIcon: false, iconImg: '', title: 'Java/Android', titlePos: 'bottom', subTitle: '', left: 38.54, top: 73.93 } },
            { node: 'n6', data: { isIcon: false, iconImg: '', title: 'Swift/iOS', titlePos: 'bottom', subTitle: '', left: 24.72, top: 89.52 } },
            { node: 'n7', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: 1.25, top: 100 } },
            { node: 'n8', data: { isIcon: false, iconImg: '', title: 'C/C++', titlePos: 'bottom', subTitle: '', left: 67.92, top: 64.8 } },
            { node: 'n10', data: { isIcon: false, iconImg: '', title: 'IoT', titlePos: 'right', subTitle: '', left: 85.63, top: 87.09 } },
            { node: 'n11', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: 83.89, top: 100, } },
            { node: 'n12', data: { isIcon: false, iconImg: '', title: 'AI/ML', titlePos: 'left', subTitle: '', left: 63.26, top: 90.74 } },
        ],
        totalLevels: 3,
        paths: [
            { path: ['n0', 'n1', 'n2'], level: 1 },
            { path: ['n2', 'n3', 'n4', 'n5', 'n6', 'n7'], level: 2 },
            { path: ['n2', 'n8', 'n10'], level: 2 },
            { path: ['n10', 'n11'], level: 3 },
            { path: ['n10', 'n12'], level: 3 }
        ],
        svgW: window.innerWidth,
        svgH: window.innerHeight
    },
    techstackConstellation_portrait:{
        nodes: [
            { node: 'n0', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: ((window.innerWidth<window.innerHeight)||isIOSAndroid)?100:87.7, top: 0 } },
            { node: 'n1', data: { isIcon: false, iconImg: '', title: 'Javascript', titlePos: 'left', subTitle: '', left: 82, top: 23.63 } },
            { node: 'n2', data: { isIcon: false, iconImg: '', title: 'MongoDB', titlePos: 'right', subTitle: '', left: 57, top: 38.25 } },
            { node: 'n3', data: { isIcon: false, iconImg: '', title: 'node.js', titlePos: 'top', subTitle: '', left: 26, top: 37.88 } },
            { node: 'n4', data: { isIcon: false, iconImg: '', title: 'PostgreSQL', titlePos: 'right', subTitle: '', left: 9.5, top: 49.21 } },
            { node: 'n5', data: { isIcon: false, iconImg: '', title: 'AI/ML', titlePos: 'bottom', subTitle: '', left: 75.54, top: 76.93 } },
            { node: 'n6', data: { isIcon: false, iconImg: '', title: 'IoT', titlePos: 'top', subTitle: '', left: 24.72, top: 89.52 } },
            { node: 'n8', data: { isIcon: false, iconImg: '', title: 'Swift/iOS', titlePos: 'bottom', subTitle: '', left: 44, top: 52 } },
            { node: 'n10', data: { isIcon: false, iconImg: '', title: 'Java/Android', titlePos: 'right', subTitle: '', left: 70, top: 67 } },        
            { node: 'n12', data: { isIcon: false, iconImg: '', title: 'C/C++', titlePos: 'bottom', subTitle: '', left: 50, top: 80.74 } },
        ],
        totalLevels: 3,
        paths: [
            { path: ['n0', 'n1', 'n2'], level: 1 },
            { path: ['n2', 'n3', 'n4'], level: 2 },
            { path: ['n2', 'n8', 'n10'], level: 2 },
            { path: ['n10', 'n5'], level: 3 },
            { path: ['n10', 'n12', 'n6'], level: 3 }
        ],
        svgW: window.innerWidth,
        svgH: window.innerHeight
    },

    /*-------Client Constellation---------------------------------------------*/
    clientConstellation:{
        nodes: [
            { node: 'n0', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: 100, top: 37.74 } },
            { node: 'n1', data: { isIcon: true, iconImg: medantaImg, title: 'Medanta', titlePos: 'bottom', subTitle: '', left: 82.22, top: 26.21 } },
            { node: 'n2', data: { isIcon: true, iconImg: airtelImg, title: 'Airtel', titlePos: 'left', subTitle: '', left: 61.04, top: 34.47 } },
            { node: 'n3', data: { isIcon: true, iconImg: itcImg, title: 'ITC Ltd', titlePos: 'right', subTitle: '', left: 93.26, top: 59.83 } },
            { node: 'n4', data: { isIcon: true, iconImg: gtsImg, title: 'Going to School', titlePos: 'bottom', subTitle: '', left: 85.56, top: 86.29 } },
            { node: 'n5', data: { isIcon: true, iconImg: kokilabenImg, title: 'Kokilaben Dhirubhai <br>Ambani Hosp.', titlePos: 'bottom', subTitle: '', left: 58.68, top: 81.19 } },
            { node: 'n6', data: { isIcon: true, iconImg: amriImg, title: 'AMRI Hospitals', titlePos: 'top', subTitle: '', left: 41.18, top: 80.1 } },
            { node: 'n7', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: 43.54, top: 100, } },
            { node: 'n8', data: { isIcon: true, iconImg: denImg, title: 'Den', titlePos: 'bottom', subTitle: '', left: 27.08, top: 79 } },
            { node: 'n9', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: 0, top: 100, } },
        ],
        totalLevels: 3,
        paths: [
            { path: ['n0', 'n1', 'n2', 'n3'], level: 1 },
            { path: ['n3', 'n4'], level: 2 },
            { path: ['n3', 'n5', 'n6'], level: 2 },
            { path: ['n6', 'n7'], level: 3 },
            { path: ['n6', 'n8', 'n9'], level: 3 }
        ],
        svgW: window.innerWidth,
        svgH: window.innerHeight
    },
    clientConstellation_portrait:{
        nodes: [
            { node: 'n0', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: 100, top: 0 } },
            { node: 'n1', data: { isIcon: true, iconImg: medantaImg, title: 'Medanta', titlePos: 'bottom', subTitle: '', left: 82.22, top: 15 } },
            { node: 'n2', data: { isIcon: true, iconImg: airtelImg, title: 'Airtel', titlePos: 'left', subTitle: '', left: 31, top: 25 } },
            { node: 'n3', data: { isIcon: true, iconImg: itcImg, title: 'ITC Ltd', titlePos: 'bottom', subTitle: '', left: 17, top: 50 } },
            { node: 'n4', data: { isIcon: true, iconImg: gtsImg, title: 'Going to School', titlePos: 'bottom', subTitle: '', left: 85, top: 37 } },
            { node: 'n5', data: { isIcon: true, iconImg: kokilabenImg, title: 'Kokilaben Dhirubhai <br>Ambani Hosp.', titlePos: 'bottom', subTitle: '', left: 55, top: 51 } },
            { node: 'n6', data: { isIcon: true, iconImg: amriImg, title: 'AMRI Hospitals', titlePos: 'bottom', subTitle: '', left: 36, top: 80.1 } },        
            { node: 'n8', data: { isIcon: true, iconImg: denImg, title: 'Den', titlePos: 'bottom', subTitle: '', left: 66, top: 82 } },
            { node: 'n9', data: { isIcon: false, iconImg: '', title: '', titlePos: '', subTitle: '', left: 100, top: 100, } },
        ],
        totalLevels: 1,
        paths: [
            { path: ['n0', 'n1', 'n2', 'n3', 'n4', 'n5', 'n6', 'n8', 'n9'], level: 1 },
        ],
        svgW: window.innerWidth,
        svgH: window.innerHeight
    }
};

function v3(x,y,z){return {x:x,y:y,z:z};}

export default DP;
